<template>
  <div>
    <resize-box @resize="resize">
      <!-- 過去映像 -->
      <div
        v-if="useVideo"
        :style="videoStyle"
        :class="{ normal: isLowerMinScale, fisheye: !isLowerMinScale }"
        @wheel.prevent="wheel"
        ref="fisheyeViewerWrap"
      >
        <!-- デワープ -->
        <div ref="fisheyeViewer" class="fisheye_viewer" />
        <!-- 標準(デワープの描画データとしても利用) -->
        <div class="normal_viewer video_wrap">
          <video
            ref="video"
            id="video"
            :src="videoPath"
            muted
            playsinline
            autoplay
            crossorigin="anonymous"
            @loadeddata="$emit('loadeddata', $refs.video)"
            @ended="$emit('ended')"
            @error="$emit('error')"
          ></video>
        </div>
      </div>
      <!-- LIVE -->
      <div
        v-else
        class="live-viewer"
        :style="videoStyle"
        :class="{ normal: isLowerMinScale, fisheye: !isLowerMinScale }"
        @wheel.prevent="wheel"
        ref="fisheyeViewerWrap"
      >
        <!-- デワープ -->
        <div ref="fisheyeViewer" class="fisheye_viewer" />
        <!-- 標準 -->
        <canvas
          ref="normalViewer"
          :width="cameraDetail.image_rs_width"
          :height="cameraDetail.image_rs_height"
          :style="canvasStyle"
        />
      </div>
    </resize-box>
    <!-- コーチマーク -->
    <transition>
      <div v-if="isShowCoachMark" class="coach_mark">
        <div class="coach_mark_inner">
          <img src="@/assets/images/mouse_wheel.svg" />
          <p v-html="$t('fisheye_viewer.coach_mark_message')"></p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ResizeBox from '@/components/atoms/ResizeBox'
import FISHEYE from '@/js/const/fisheye'
import { mapGetters, mapMutations } from 'vuex'
import { useBrowserName } from '@/js/utils/util'
let THREE = null
const archiveImg = new Image()
const MESH_NUM = 32

export default {
  name: 'FisheyeViewer',
  components: { ResizeBox },
  data() {
    return {
      imgPath: null,
      source: null, // 変換元のDOM,
      exponent: 10, // 2のn乗になるテクスチャの大きさのnの値
      canvas: null,
      camera: null,
      renderer: null,
      fisheyeCtx: null,
      normalViewerCtx: null,
      region: { centerX: 300, centerY: 300, radius: 300 }, // 正方形テクスチャから切り取る領域,
      pos: [0, 0, 0, 0, 0, 0, 0, 0],
      meshes: [],
      textures: [],
      prevEuler: { pitch: 0, yaw: 0 }, // pitch:Y軸方向の回転値、yaw:Z軸方向の回転値
      dragging: false,
      renderTimer: null,
      isShowCoachMark: false,
    }
  },
  props: {
    useVideo: {
      type: Boolean,
      default: false,
    },
    displaySize: {
      type: Object,
    },
    liveImg: {
      type: HTMLElement,
    },
    liveImageSrc: {
      type: String,
    },
    videoPath: {
      type: String,
    },
    cameraDetail: {
      type: Object,
    },
    isPaused: {
      type: Boolean,
    },
  },
  emits: ['loadeddata', 'ended', 'error', 'setFisheyeViewerElement', 'setVideoElement'],
  beforeUnmount() {
    clearTimeout(this.renderTimer)
    this.resetState()
  },
  async mounted() {
    if (window.navigator.msSaveBlob) {
      // IEでは後続の処理を実行しない
      return
    }

    THREE = await import('three')
    const renderElm = this.useVideo ? this.$refs.video : this.liveImg

    // webgl関連の初期化
    this.initFisheyeViewer()
    // canvasに描画するdomをセット
    this.setSrc(renderElm)
    // 表示サイズをセット
    this.setCanvasSize(this.displaySize)
    // 映像の表示位置をセット
    this.setCameraPose({ pitch: 1.5, yaw: 0 })
    // 拡大率をセット(初期表示時最小値をセットして全体表示する)
    this.setZoom(this.calibration.minZoom)
    // 魚眼の円の位置を調整する
    this.setFisheyeRegion({
      centerX: this.cameraSize.width / 2,
      centerY: this.cameraSize.height / 2,
      radius: this.calibration.radius,
    })

    // UI操作用のイベントを設定
    this.setEvent()

    // ライブ表示の場合のみ実行
    if (!this.useVideo) {
      this.initNormalLiveViewer()
    }

    // 360度表示をcanvasを追加
    this.$refs.fisheyeViewer.appendChild(this.canvas)
    await this.render()

    // コーチマークを表示
    this.showCoachMark()
    this.$emit('setFisheyeViewerElement', this.$refs.fisheyeViewerWrap)
    this.$emit('setVideoElement', this.$refs.video)
  },
  methods: {
    ...mapMutations({
      setDisplayState: 'fisheye/setDisplayState',
      resetState: 'fisheye/resetState',
      setDisplayedCoachMark: 'fisheye/setDisplayedCoachMark',
    }),
    /**
     * ライブ標準ビュアーの初期化
     */
    initNormalLiveViewer() {
      this.normalViewerCtx = this.$refs.normalViewer.getContext('2d')
    },
    /**
     * 360度ビュアーの初期化(webgl関連)
     */
    initFisheyeViewer() {
      this.camera = new THREE.PerspectiveCamera(30, 4 / 3, 1, 1000)
      this.renderer = new THREE.WebGLRenderer({
        // 画像ダウンロードでcanvasを画像に変換する際に必要
        preserveDrawingBuffer: true,
      })
      this.scene = new THREE.Scene()
      this.canvas = this.renderer.domElement
      this.fisheyeCtx = document.createElement('canvas').getContext('2d')
      this.local = new THREE.Object3D()
      // ドラッグ用当たり判定メッシュ
      const sphereGeom = new THREE.SphereGeometry(100, 32, 16)
      const blueMaterial = new THREE.MeshBasicMaterial({
        color: 0x0000ff,
        side: THREE.BackSide,
        transparent: true,
        opacity: 0,
      })
      this.collisionSphere = new THREE.Mesh(sphereGeom, blueMaterial)

      this.scene.add(this.local)
      this.scene.add(this.collisionSphere)
      this.scene.add(this.camera)

      // 画像キャプチャで外部から参照する為idを付与する
      this.canvas.setAttribute('id', 'fisheye-viewer')
    },
    /**
     * UI操作用のイベントを設定
     */
    setEvent() {
      this.canvas.addEventListener('mousemove', (e) => {
        if (this.dragging) {
          this.drag('move', e.offsetX, e.offsetY)
        }
      })
      this.canvas.addEventListener('mousedown', (e) => {
        this.dragging = true
        this.drag('start', e.offsetX, e.offsetY)
      })
      this.canvas.addEventListener('mouseup', () => {
        this.dragging = false
      })
      this.canvas.addEventListener('mouseleave', () => {
        this.dragging = false
      })
    },
    /**
     * 360度ビュアーの初期化(webgl関連)
     */
    setSrc(source) {
      if (source == null || source === this.source) {
        return
      }
      this.source = source
      this.load()
    },
    /**
     * 魚眼の円の位置を調整する
     */
    setFisheyeRegion(prop) {
      this.region = prop
      this.updateFisheyeRegion()
    },
    /**
     * 表示サイズを設定
     * @param {Object} size 表示するcanvasの幅、高さ
     */
    setCanvasSize(size) {
      // 現在のレンダラを現在のピクセルサイズに最適化する
      this.renderer.setSize(size.width, size.height)
      if (this.camera instanceof THREE.PerspectiveCamera) {
        this.camera.aspect = size.width / size.height
        this.camera.updateProjectionMatrix()
      }
    },
    /**
     * 拡大率を設定
     * @param {Number} scale 拡大率
     */
    setZoom(scale) {
      this.camera.zoom = scale
      this.camera.updateProjectionMatrix()
      // zoomの値に合わせてpitchも変更
      if (this.cameraPitchMin < -this.local.rotation.x) {
        this.setPitch(this.cameraPitchMin)
      } else if (this.cameraPitchMax > -this.local.rotation.x) {
        this.setPitch(this.cameraPitchMax)
      }
    },
    /**
     * 映像の表示位置の変更(３次元ベクトルの回転を設定)
     * @param {Object} scale 拡大率
     */
    setCameraPose({ pitch, yaw }) {
      this.setPitch(pitch)
      this.setYaw(yaw)
    },
    /**
     * 映像のY軸歩行の回転率を設定
     * @param {Number} pitch Y軸の回転率
     */
    setPitch(pitch) {
      this.local.rotation.x = -pitch
    },
    /**
     * 映像のZ軸歩行の回転率を設定
     * @param {Number} yaw Z軸の回転率
     */
    setYaw(yaw) {
      if (this.meshes.length === 0) {
        return
      }

      this.meshes[0].rotation.z = yaw
    },
    /**
     * 魚眼クリッピング領域の計算
     */
    updateFisheyeRegion() {
      const pow = Math.pow(2, this.exponent)
      const { radius, centerX, centerY } = this.region
      const clippedWidth = radius * 2
      const clippedHeight = radius * 2
      const left = centerX - radius
      const top = centerY - radius

      let [sx, sy] = [left, top]
      let [sw, sh] = [clippedWidth, clippedHeight]
      let [dx, dy] = [0, 0]
      let [dw, dh] = [pow, pow] // 縮小先の大きさ
      // ネガティブマージン 対応
      if (left < 0) {
        sx = 0
        sw = clippedWidth - left
        dx = (-left * pow) / clippedWidth
        dw = (sw * pow) / clippedWidth
      }
      if (top < 0) {
        sy = 0
        sh = clippedHeight - top
        dy = (-top * pow) / clippedHeight
        dh = (sh * pow) / clippedHeight
      }
      this.pos = [sx, sy, sw, sh, dx, dy, dw, dh]
      // 2^nな縮拡先の大きさ
      this.fisheyeCtx.canvas.width = pow
      this.fisheyeCtx.canvas.height = pow
    },
    /**
     * テクスチャ用のVideo、画像のロード
     */
    load() {
      // 以前のパノラマを消す
      this.unload()

      const texture = new THREE.Texture(this.fisheyeCtx.canvas)
      const mesh = this.createFisheyeMesh(texture)

      this.local.add(mesh)
      this.meshes.push(mesh)
      this.textures.push(texture)
    },
    /**
     * 以前のリソースを消す
     */
    unload() {
      this.meshes.forEach((mesh) => {
        this.local.remove(mesh)
        mesh.geometry.dispose()
        mesh.material.dispose()
      })
      this.textures.forEach((tex) => {
        tex.dispose()
      })
      this.meshes = []
      this.textures = []
    },
    /**
     * cam.src の size にテクスチャを合わせる
     */
    resize() {
      // TODO とりあえずcanvasのリサイズを入れる
      if (this.renderer) {
        this.setCanvasSize(this.displaySize)
      }

      if (this.source) {
        this.updateFisheyeRegion()
      }
    },
    /**
     * 正方形テクスチャを半球に投影したマテリアルとそのメッシュを得る
     * @param {Object} texture 正方形テクスチャ
     */
    createFisheyeMesh(texture) {
      // 正方形テクスチャを仮定
      // SphereGeometry(radius, widthSegments, heightSegments, phiStart, phiLength, thetaStart, thetaLength)
      const sphere = new THREE.SphereGeometry(1000, MESH_NUM, MESH_NUM, Math.PI, Math.PI)

      // TODO v0.83以降だとcomputeBoundingSphereを実行しないとboundingSphereが取得できない
      sphere.computeBoundingSphere()
      // TODO 新しいバージョンだと歪みが大きい。無理やり書き換える
      // https://ghe.dev.ciaoinc.jp/CiaoInc/ciaocamera-main/issues/1169#issuecomment-9645
      sphere.boundingSphere.center = new THREE.Vector3(0, 1, 0)
      sphere.boundingSphere.radius = 1000

      this.updateUv(sphere)

      const mat = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        map: texture,
        side: THREE.BackSide,
      })
      const mesh = new THREE.Mesh(sphere, mat)
      mesh.rotation.x = (Math.PI * 1) / 2 // 北緯側の半球になるように回転
      return mesh
    },
    /**
     * UV座標を更新
     * @param {THREE.SphereGeometry} sphere テクスチャを描画するジオメトリ
     */
    updateUv(sphere) {
      const radius = sphere.boundingSphere.radius
      const position = sphere.attributes.position
      const uvAttribute = sphere.attributes.uv

      for (let i = 0; i < position.count; i++) {
        const x = position.getX(i)
        const y = position.getY(i)
        const u = (x + radius) / (2 * radius)
        const v = (y + radius) / (2 * radius)

        uvAttribute.setXY(i, u, v)
      }
    },
    /**
     * 描画する
     * needsUpdateして render
     */
    async render() {
      if (this.renderTimer) {
        clearTimeout(this.renderTimer)
      }

      let source = this.source

      if (!this.isLowerMinScale) {
        // TODO Safariだけ動画の解像度が1:1の場合に正しく表示されないので描画処理を分ける(過去映像のみ)
        // 原因はdrawImageのキャプチャ範囲だとは思うが、特定できていない
        if (this.useVideo && useBrowserName() === 'safari') {
          try {
            source = await this.getArchiveImgForSafari()

            // TODO デワープ表示に切り替えた際に映像が止まる場合があるので、ユーザーが停止させてなければば再生する
            // 原因はわからないが、videoを非表示にするタイミングの問題かもしれない
            if (this.source.paused && !this.isPaused) {
              this.source.play()
            }
          } catch (e) {
            // 映像切替時にエラーになる場合があるが何もしない
          }
        }
        const [sx, sy, sw, sh, dx, dy, dw, dh] = this.pos
        // TODO クリアすると画面がちらつく場合がある
        // this.fisheyeCtx.canvas.width = this.fisheyeCtx.canvas.width
        this.fisheyeCtx.drawImage(source, sx, sy, sw, sh, dx, dy, dw, dh)

        this.textures.forEach((tex) => {
          tex.needsUpdate = true
        })

        this.renderer.render(this.scene, this.camera)
      } else if (!this.useVideo) {
        this.normalViewerCtx.drawImage(
          this.liveImg,
          0,
          0,
          this.cameraDetail.image_rs_width,
          this.cameraDetail.image_rs_height
        )
      }

      // レンダリング一定周期でおこなう(requestAnimationFrameを利用すると過去映像の再生速度が勝手に変わる場合がある)
      this.renderTimer = setTimeout(this.render, this.updateInterval)
    },
    /**
     * Safari用のvideoを画像に変換して返却
     * @returns {Image} 変換された画像
     */
    getArchiveImgForSafari() {
      return new Promise((resolve, reject) => {
        const url = window.URL || window.webkitURL

        if (archiveImg.src) {
          // メモリから削除
          url.revokeObjectURL(archiveImg.src)
        }

        // safariは解像度が1:1出ない場合に正しく描画されないので
        // 過去映像は一度画像に変換してから描画する
        const canvas = document.createElement('canvas')
        canvas.width = this.source.videoWidth
        canvas.height = this.source.videoHeight
        const context = canvas.getContext('2d')
        context.drawImage(this.source, 0, 0, this.source.videoWidth, this.source.videoHeight)

        // TODO canvas.toDataURL() で画像化するとarchiveImg = nullしてもメモリが開放されないのでやむなくblobに変換
        canvas.toBlob((blob) => {
          archiveImg.onload = () => resolve(archiveImg)
          archiveImg.onerror = (e) => reject(e)
          if (blob) {
            archiveImg.src = url.createObjectURL(blob)
          } else {
            // 映像切替のタイミングでblobがnullになる場合がある
            reject(new Error('archive image load error'))
          }
          canvas.remove()
        })
      })
    },
    /**
     * UIの操作
     * @param {String} type ドラックの開始、ドラック中の種別
     * @param {Number} offsetX event.offsetX
     * @param {Number} offsetY event.offsetY
     */
    drag(type, offsetX, offsetY) {
      const { width, height } = this.canvasSize
      // 取得したスクリーン座標を-1〜1に正規化する（WebGLは-1〜1で座標が表現される）
      const mouseX = -(offsetX / width) * 2 + 1
      const mouseY = -(offsetY / height) * 2 + 1
      const pos = new THREE.Vector3(mouseX, mouseY, 1)

      // pos はスクリーン座標系なので、オブジェクトの座標系に変換
      // オブジェクト座標系は今表示しているカメラからの視点なので、第二引数にカメラオブジェクトを渡す
      // new THREE.Projector.unprojectVector(pos, camera); ↓最新版では以下の方法で得る
      pos.unproject(this.camera)
      // 始点、向きベクトルを渡してレイを作成
      const ray = new THREE.Raycaster(
        this.camera.position,
        pos.sub(this.camera.position).normalize()
      )
      // 衝突判定対象のメッシュのリストを取得
      const objs = ray.intersectObjects([this.collisionSphere])
      // https://threejs.org/docs/api/core/Raycaster.html
      if (objs.length === 0) {
        return
      }
      const obj = objs[0]
      if (type === 'start') {
        this.prevEuler = this.toEuler(obj.point)
        return
      }
      const curr = this.toEuler(obj.point)
      let pitch = -this.local.rotation.x - (curr.pitch - this.prevEuler.pitch)
      const yaw = this.yaw - (curr.yaw - this.prevEuler.yaw)

      if (pitch < this.cameraPitchMax) {
        pitch = this.cameraPitchMax
      }
      if (pitch > this.cameraPitchMin) {
        pitch = this.cameraPitchMin
      }

      this.setPitch(pitch)
      this.setYaw(yaw)

      this.prevEuler = curr
    },
    /**
     * オイラー角に変換
     * ptrは画面
     * 奥へ向かって -z軸、
     * 右へ向かって x軸
     * 上に向かって y軸
     * であるような右手系
     * @param {Object} ptr 衝突判定対象のメッシュのリスト
     * */
    toEuler(ptr) {
      const [x, y, z] = [-ptr.z, ptr.x, ptr.y]
      // オイラー角に変換
      const yaw = -Math.atan2(y, x)
      const pitch = Math.atan2(z, Math.sqrt(x * x + y * y))
      return { yaw, pitch }
    },
    /**
     * マウスホイールの操作
     * @param {Object} e イベントオブジェクト
     */
    wheel(e) {
      const incrementVal = 0.005
      let zoom = this.zoom
      if (e.wheelDelta > 0 && zoom < this.calibration.maxZoom) {
        zoom += incrementVal
      } else if (e.wheelDelta < 0 && zoom >= this.calibration.minZoom) {
        zoom -= incrementVal
      }
      this.setZoom(zoom)
    },
    /**
     * マウスホイール操作のコーチマークを表示
     */
    showCoachMark() {
      if (!this.displayedCoachMark) {
        this.isShowCoachMark = true
        setTimeout(() => {
          this.isShowCoachMark = false
          this.setDisplayedCoachMark(true)
        }, 3000)
      }
    },
  },
  computed: {
    ...mapGetters({
      displayedCoachMark: 'fisheye/displayedCoachMark',
    }),
    calibration() {
      // TODO キャリブレーションの設定定数に無い縦横比の場合は1280x720とする
      return FISHEYE.CALIBRATION[this.cameraResolutionStr] || FISHEYE.CALIBRATION['1280_720']
    },
    canvasSize() {
      return this.renderer.getSize(new THREE.Vector2())
    },
    config() {
      const { region, zoom, cameraPose: direction } = this
      return { region, direction, zoom }
    },
    zoom() {
      return this.camera.zoom
    },
    yaw() {
      if (this.meshes.length === 0) {
        return 0
      }
      return this.meshes[0].rotation.z
    },
    cameraPitchMax() {
      return this.calibration.pitchMax - this.calibration.pitchRangeForZoom * this.zoom
    },
    cameraPitchMin() {
      return this.calibration.pitchMin + this.calibration.pitchRangeForZoom * this.zoom
    },
    videoStyle() {
      return { height: `${this.displaySize.height}px`, width: `${this.displaySize.width}px` }
    },
    isLowerMinScale() {
      if (!this.camera) return
      return this.calibration.minZoom >= this.zoom
    },
    cameraSize() {
      const size = this.cameraResolutionStr.split('_')
      return {
        width: size[0],
        height: size[1],
      }
    },
    /**
     * カメラのサイズ(幅、高さ)
     * @return {String} 幅と高さを"_"で結合した文字列(FisheyeViewerで利用)
     */
    cameraResolutionStr() {
      return `${this.cameraDetail.image_rs_width}_${this.cameraDetail.image_rs_height}`
    },
    /**
     * 更新頻度(FPS)
     * @return {Number} アップデート間隔
     */
    updateInterval() {
      const ms = 1000 / this.cameraDetail.image_fps
      // ズーム、パン操作で最低200ミリ秒での更新が必要
      return ms < 200 ? ms : 200
    },
    /**
     * canvasのstyle(画像が劣化しないようにする為表示サイズはstyleで指定)
     * @return {Object} style
     */
    canvasStyle() {
      return {
        width: this.displaySize.width + 'px',
        height: this.displaySize.height + 'px',
      }
    },
  },
  watch: {
    liveImageSrc(v) {
      if (v) {
        // 画像更新時もレンダリングする
        this.render()
      }
    },
    isLowerMinScale(v) {
      // FisheyeViewer内での360度表示、通教表示の状態をstoreにセットする
      const displayState = v ? 'normal' : 'fisheye'
      this.setDisplayState(displayState)
    },
    displaySize() {
      this.resize()
    },
  },
}
</script>

<style scoped lang="scss">
.fisheye {
  .normal_viewer {
    // display:noneの場合、Safariで標準・360度表示切り替え時に標準表示が表示されない問題があった為visibilityに変更
    visibility: hidden;
  }
}

.normal {
  .normal_viewer {
    visibility: visible;
  }
  .fisheye_viewer {
    display: none;
  }
  .video_wrap {
    video {
      width: 100%;
      height: 100%;
    }
  }
}
.live-viewer {
  overflow: hidden;
  margin: 0 auto;

  &.normal {
    .fisheye_viewer {
      position: absolute;
      top: -5000px;
    }
  }
}

.controller {
  padding: $size-l $size-m $size-m;
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  background: rgba(#fff, 0.5);
  .controller_wrap {
    div {
      display: flex;
      align-items: center;
      &:last-child {
        flex: 1;
        margin-left: $size-s;
      }
    }
  }
  .close_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.coach_mark {
  width: 150px;
  margin-left: -75px;
  @include x_center;
  @include y_center;
  background: rgba($color-dark1, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius-m;
  padding: $size-m;
  .coach_mark_inner {
    text-align: center;
    line-height: 1.4;
    img {
      width: 55px;
      padding-bottom: $size-s;
    }
    p {
      @include fs($font-size-7);
      color: $color-white;
    }
  }
}

@include fade_animation($duration-fast);
</style>
