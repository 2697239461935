import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import detail from '@/store/modules/detail'
import tag from '@/store/modules/tag'
import notification from '@/store/modules/notification'
import multiVideos from '@/store/modules/multiVideos'
import childUsers from '@/store/modules/childUsers'
import webPush from '@/store/modules/webPush'
import fisheye from '@/store/modules/fisheye'
import live from '@/store/modules/live'
import timeLapse from '@/store/modules/timelapse'
import personTrackingResultDetail from '@/store/modules/personTrackingResultDetail'
import personTrackingSetting from '@/store/modules/personTrackingSetting'
import operationAnalysis from '@/store/modules/operationAnalysis'
import peopleCounter from '@/store/modules/peopleCounter'
import app from '@/store/modules/app'
import APP from '@/js/const/app'

export default createStore({
  strict: true,
  modules: {
    auth,
    detail,
    tag,
    notification,
    multiVideos,
    childUsers,
    webPush,
    timeLapse,
    fisheye,
    live,
    personTrackingResultDetail,
    personTrackingSetting,
    operationAnalysis,
    app,
    peopleCounter,
  },
  plugins: [
    createPersistedState({
      key: APP.APP_NAME,
      paths: [
        'auth.login',
        'app',
        'detail.selectedEventFilter',
        'detail.eventToolTipState',
        'detail.useCameraInfo', // TODO 後で消す
        'detail.cameraInfo',
        'detail.useFullSizeVideo',
        'detail.liveState',
        'notification.tooltipState',
        'multiVideos.liveState',
        'webPush.notificationSubscribeDialogExpire',
        'timeLapse.sort',
      ],
      storage: window.localStorage,
    }),
  ],
})
